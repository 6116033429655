import React from "react"
import { LayoutNotFound } from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
    <LayoutNotFound>
        <Seo title="Page Not Found"/>
    </LayoutNotFound>
)

export default NotFoundPage
